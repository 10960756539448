/**
****************************************************************************************
Project: Readabl
File: reducer.js
Description: This file contains the redux store update based on action types.
© Healthcare Triangle, Inc
****************************************************************************************
**/

import {SA_ACTIONS } from "../actions/actionTypes";

const initialState = { docstuck : [] };
export function docstuck(state = initialState, action) {
    switch(action.type) {
        case SA_ACTIONS.GET_DOC_STUCK_SUCCESS:
            return  { ...state, docstuck: action.docstuck }
        case SA_ACTIONS.GET_DOC_STUCK_ERROR:
            return {...state, hasError: action.hasError}
        case SA_ACTIONS.GET_DOC_STUCK_LOADING:
            return {...state, isLoading : action.isLoading}
        default:
            return state;
    }
}