/**
****************************************************************************************
Project: Readabl
File: reducer.js
Description: This file contains the redux store update based on action types.
© Healthcare Triangle, Inc
****************************************************************************************
**/

import { DOC_MGMT_ACTIONS } from "../actions/actionTypes";

export function categoryitems(state = [], action) {
  switch (action.type) {
    case DOC_MGMT_ACTIONS.GET_CATEGORY_COUNT_SUCCESS:
      return { ...state, categoryitems: action.categoryitems };
    case DOC_MGMT_ACTIONS.GET_CATEGORY_COUNT_ERROR:
      return { ...state, hasError: action.hasError };
    default:
      return state;
  }
}

export function tabledata(state = [], action) {
  switch (action.type) {
    case DOC_MGMT_ACTIONS.GET_DOCS_TABLE_DATA_SUCCESS:
      return { ...state, tabledata: action.tabledata };
    case DOC_MGMT_ACTIONS.GET_DOCS_TABLE_DATA_ERROR:
      return { ...state, hasError: action.hasError };
    default:
      return state;
  }
}

export function totalrecords(state = [], action) {
  switch (action.type) {
    case DOC_MGMT_ACTIONS.GET_PAGE_LIMIT_SUCCESS:
      return { ...state, totalrecords: action.totalrecords };
    case DOC_MGMT_ACTIONS.GET_PAGE_LIMIT_ERROR:
      return { ...state, hasError: action.hasError };
    default:
      return state;
  }
}

export function alllocations(state = [], action) {
  switch (action.type) {
    case DOC_MGMT_ACTIONS.GET_ALL_LOCATIONS_DOC_SUCCESS:
      return { ...state, alllocations: action.alllocations };
    case DOC_MGMT_ACTIONS.GET_ALL_LOCATIONS_DOC_ERROR:
      return { ...state, hasError: action.hasError };
    default:
      return state;
  }
}

export function doc_image_url(state = [], action) {
  switch (action.type) {
    case DOC_MGMT_ACTIONS.GET_IMAGE_URL_SUCCESS:
      return { ...state, doc_image_url: action.doc_image_url };
    case DOC_MGMT_ACTIONS.GET_IMAGE_URL_ERROR:
      return { ...state, hasError: action.hasError };
    default:
      return state;
  }
}
export function locations_user(state = [], action) {
  switch (action.type) {
    case DOC_MGMT_ACTIONS.GET_LOCATIONS_USER_SUCCESS:
      return { ...state, locations_user: action.locations_user };
    case DOC_MGMT_ACTIONS.GET_LOCATIONS_USER_ERROR:
        return { ...state, hasError: action.hasError };
    default:
      return state;
  }
}

export function flags(state = [], action) {
  switch (action.type) {
    case DOC_MGMT_ACTIONS.GET_FLAGS_SUCCESS:
      return { ...state, flags: action.flags };
    case DOC_MGMT_ACTIONS.GET_FLAGS_ERROR:
        return { ...state, hasError: action.hasError };
    default:
      return state;
  }
}
/*===========not used===============*/
export function results(state = [], action) {
  switch (action.type) {
    case DOC_MGMT_ACTIONS.POST_FILTER_SUCCESS:
      return { ...state, results: action.results };
    case DOC_MGMT_ACTIONS.POST_FILTER_ERROR:
        return { ...state, hasError: action.hasError };
    default:
      return state;
  }
}