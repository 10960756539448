/**
****************************************************************************************
Project: Readabl
File: actionTypes.js
Description: This file contains the action types.
© Healthcare Triangle, Inc
****************************************************************************************
**/

export const DOC_MGMT_ACTIONS = {
    GET_CATEGORY_COUNT_ERROR: 'GET_CATEGORY_COUNT_ERROR',
    GET_CATEGORY_COUNT_LOADING: 'GET_CATEGORY_COUNT_LOADING',
    GET_CATEGORY_COUNT_SUCCESS: 'GET_CATEGORY_COUNT_SUCCESS',
    GET_DOCS_TABLE_DATA_ERROR: 'GET_DOCS_TABLE_DATA_ERROR',
    GET_DOCS_TABLE_DATA_LOADING: 'GET_DOCS_TABLE_DATA_LOADING',
    GET_DOCS_TABLE_DATA_SUCCESS: 'GET_DOCS_TABLE_DATA_SUCCESS',
    GET_PAGE_LIMIT_ERROR: 'GET_PAGE_LIMIT_ERROR',
    GET_PAGE_LIMIT_LOADING: 'GET_PAGE_LIMIT_LOADING',
    GET_PAGE_LIMIT_SUCCESS: 'GET_PAGE_LIMIT_SUCCESS',
    GET_ALL_LOCATIONS_DOC_ERROR: 'GET_ALL_LOCATIONS_ERROR',
    GET_ALL_LOCATIONS_DOC_LOADING: 'GET_ALL_LOCATIONS_LOADING',
    GET_ALL_LOCATIONS_DOC_SUCCESS: 'GET_ALL_LOCATIONS_SUCCESS',

    GET_IMAGE_URL_LOADING: 'GET_IMAGE_URL_LOADING',
    GET_IMAGE_URL_ERROR: 'GET_IMAGE_URL_ERROR',
    GET_IMAGE_URL_SUCCESS: 'GET_IMAGE_URL_SUCCESS',

    POST_FILTER_LOADING : 'POST_FILTER_LOADING',
    POST_FILTER_ERROR : 'POST_FILTER_ERROR',
    POST_FILTER_SUCCESS : 'POST_FILTER_SUCCESS',

    GET_LOCATIONS_USER_ERROR: 'GET_LOCATIONS_USER_ERROR',
    GET_LOCATIONS_USER_LOADING: 'GET_LOCATIONS_USER_LOADING',
    GET_LOCATIONS_USER_SUCCESS: 'GET_LOCATIONS_USER_SUCCESS',

    GET_FLAGS_ERROR: 'GET_FLAGS_ERROR',
    GET_FLAGS_LOADING: 'GET_FLAGS_LOADING',
    GET_FLAGS_SUCCESS: 'GET_FLAGS_SUCCESS',

}