/**
****************************************************************************************
Project: Readabl
File: reducer.js
Description: This file contains the redux store update based on action types.
© Healthcare Triangle, Inc
****************************************************************************************
**/

import { GET_MANUAL_REVIEW_DATA_ACTIONS } from "../actions/actionTypes";

export function data(state = [], action) {
  switch (action.type) {
    case GET_MANUAL_REVIEW_DATA_ACTIONS.GET_MANUAL_REVIEW_DATA_SUCCESS:
      return { ...state, data: action.data };
    case GET_MANUAL_REVIEW_DATA_ACTIONS.GET_MANUAL_REVIEW_DATA_ERROR:
      return { ...state, hasError: action.hasError };
    default:
      return state;
  }
}

export function image_url(state = [], action) {
  switch (action.type) {
    case GET_MANUAL_REVIEW_DATA_ACTIONS.GET_IMAGE_URL_SUCCESS:
      return { ...state, image_url: action.image_url };
    case GET_MANUAL_REVIEW_DATA_ACTIONS.GET_IMAGE_URL_ERROR:
      return { ...state, hasError: action.hasError };
    default:
      return state;
  }
}

export function category_list(state = [], action) {
  switch (action.type) {
    case GET_MANUAL_REVIEW_DATA_ACTIONS.GET_CATEGORIES_SUCCESS:
      return { ...state, category_list: action.category_list };
    case GET_MANUAL_REVIEW_DATA_ACTIONS.GET_CATEGORIES_ERROR:
      return { ...state, hasError: action.hasError };
    default:
      return state;
  }
}

export function category_map(state = [], action) {
  switch (action.type) {
    case GET_MANUAL_REVIEW_DATA_ACTIONS.GET_CATEGORY_MAPPING_SUCCESS:
      return { ...state, category_map: action.category_map };
    case GET_MANUAL_REVIEW_DATA_ACTIONS.GET_CATEGORY_MAPPING_ERROR:
      return { ...state, hasError: action.hasError };
    default:
      return state;
  }
}
