/**
****************************************************************************************
Project: Readabl
File: commonFunctions.js
Description: This file contains all the reusable common functions.
© Healthcare Triangle, Inc
****************************************************************************************
**/

import { jwtDecode } from "jwt-decode";
import * as dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import React from "react";

dayjs.extend(duration);
dayjs.extend(utc);

/******* converts date to browser supported format *******/
const FormatValidDate = (date) => {
  let browserName = window.navigator.userAgent;
  if ((browserName?.toLowerCase()?.includes("safari") && !browserName?.toLowerCase()?.includes("chrome") ) || browserName?.toLowerCase()?.includes("firefox")) {
    if (!date) return "";
    const [datePart, timePart] = date?.split(" ") || [];
    const [day, month, year] = datePart?.split("-")?.map(Number) || [];
    const [hours, minutes, seconds] = timePart?.split(":")?.map(Number) || [];
    const jsDate = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));
    return dayjs(jsDate);
  }
  return date;
}

/************** converts utc to local time zone **************/ 

export const ConvertUtcToLocal = (date, format = "MM/DD/YYYY, hh:mm:ss A") => {
  date = FormatValidDate(date);
  if(dayjs(date).isValid())
    return dayjs.utc(date).local().format(format);
  return ""
}

/************** converts local to utc time zone **************/
export const ConvertLocalToUtc = ({date, format = "MM/DD/YYYY, hh:mm:ss A", startOf, endOf}) => {
  if(date) {
    if(startOf) return dayjs(date).startOf(startOf || "day").utc().format(format);
    if(endOf) return dayjs(date).endOf(endOf || "day").utc().format(format);
  }
  return ""
}

/************** converts date and time into given format **************/
export const FormatDate = ({date, format = "LL"}) => {
  if(date) {
    date = FormatValidDate(date);
    return dayjs(date).format(format);
  }
  return ""
}

/************** provides current date in mm/dd/yyyy **************/
export const GetCurrentDateAndTime = (format = "MM/DD/YYYY hh:mm:ss A") => {
  return dayjs().format(format);
}

/************** converts the given string to ConvertToTitleCase **************/
export const ConvertToTitleCase = (str,ch) => {
  var splitStr =
    str.indexOf(ch) === -1
      ? str.toLowerCase().split(" ")
      : str.toLowerCase().split(ch);
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
};

/************** converts seconds to Minutes and seconds **************/
export const SecondsToMinutes = (sec) => {
  sec = Math.round(sec);
  var minutes =  sec / 60;
  var seconds =  sec % 60;
  return {
    minutes: minutes.toString().split(".")[0],
    seconds
  }
}

/************** check expiry of access/refresh token **************/
export const TokenExpiry = (token) => {
  var isValid = "";
  if (token) {
    const { exp } = jwtDecode(token);
    const expirationTime = exp * 1000 - 300000;
    if (Date.now() >= expirationTime) isValid = false;
    else isValid = true;
  }
  return isValid;
};
/************** check user role by decoding access token **************/
export const GetUserInfo = (token) => {
  if (token) {
    const { user } = jwtDecode(token);
    return user;
  }
};

/************** provides current time zone **************/
export const GetTimeZone = () => {
  let offset = new Date().getTimezoneOffset();
  let o = Math.abs(offset);
  return "-07:00";
  // return ((offset < 0 ? '+' : '-') + ('00' + Math.floor(o / 60)).slice(-2) + ':' + ('00' + (o % 60)).slice(-2));
}

/************** this will sort list of data based on the key provided **************/
export const SortData = (data, key, order) => {
    let tempData = order == "desc" ? data.sort((a, b) => (b[key] < a[key]) ? 1 : -1) : data.sort((a, b) => (a[key] < b[key]) ? -1 : 1)
    return tempData

}

/************** provide default date range in the given format. the start date will be the current date **************/
export const GetDefaultDate = ({duration, type, format = "YYYY-MM-DD"}) => {
  let currentDate = dayjs().format(format);
  let fromDate = dayjs().subtract(duration, type || "day").format(format);
  return {
    fromDate: fromDate,
    toDate: currentDate
  }
}

/************** this will return current date start and end time **************/
export const GetDateRange = ({type = "day", format = "MM-DD-YYYY hh:mm:ss"}) => {
    return {
      fromDate: dayjs().startOf(type).format(format),
      toDate: dayjs().endOf(type).format(format),
    }
}

/************** checks the given date is valid or not **************/
export const IsValidDate = (date) => {
  date = FormatValidDate(date);
  return dayjs(date).isValid();
}

/************** This function will generate random string **************/
export const GenerateRandomId = (length = 5) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let randomId = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomId += characters.charAt(randomIndex);
  }
  return randomId;
}

/************** this function will return truncated text **************/
export const TrimCharacters = (characters = '', length = 10) => {
  return characters.length > (length + 10)
  ? characters.substr(0, length) +
    "..." +
    characters.substr(
      characters.length - 5,
      characters.length
    )
  : characters
}

/************** this function return current time stamp **************/
export const GetCurrentTimeStamp = () => dayjs().valueOf();

/************** this function returns date objects from date string **************/
export const GetDateObject = (date) => {
  if(date && dayjs(date).isValid()) {
    return dayjs(date);
  }

  return date;
}

/************** create deep clone of the given object. used to clone react elements **************/
export const DeepClone = (obj) => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (React.isValidElement(obj)) {
    return React.cloneElement(obj);
  }

  if (Array.isArray(obj)) {
    return obj.map(item => DeepClone(item));
  }
  const clonedObj = {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      clonedObj[key] = DeepClone(obj[key]);
    }
  }
  return clonedObj;
}

/************** this function will generates search filter for audit log pages **************/
export const searchStringFilter = (col, value) => {
  let columns = [];
  if(col == "file_name") columns = [col, "original_file_name"];
  else if (col == "all") columns = [];
  else columns = [col]
  return {
     columns: columns,
     value: value
    };
};
