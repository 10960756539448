/**
****************************************************************************************
Project: Readabl
File: actionTypes.js
Description: This file contains the action types.
© Healthcare Triangle, Inc
****************************************************************************************
**/

export const PATIENT_CREATE_ACTIONS_CONSTANTS = {
    TOGGLE_PATIENT_CREATE_POPUP: "TOGGLE_PATIENT_CREATE_POPUP",
    MAXIMISE_PATIENT_CREATE_POPUP: "MAXIMISE_PATIENT_CREATE_POPUP",
    UPDATE_PATIENT_CREATE_DETAILS: "UPDATE_PATIENT_CREATE_DETAILS",
    UPDATE_DOC_DETAILS: "UPDATE_DOC_DETAILS",
}