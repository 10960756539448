/**
****************************************************************************************
Project: Readabl
File: actionCreators.js
Description: This file contains the action creators.
© Healthcare Triangle, Inc
****************************************************************************************
**/

import { USER_PROFILE_ACTIONS } from "./actionTypes";

export const userProfileActions = {};

userProfileActions.getUserProfileError = ((err) => {
    return {
        type: USER_PROFILE_ACTIONS.GET_USER_PROFILE_ERROR,
        hasError: err
    }
});

userProfileActions.getUserProfileLoading = ((bool) => {
    return {
        type: USER_PROFILE_ACTIONS.GET_USER_PROFILE_LOADING,
        isLoading: bool
    }
});

userProfileActions.getUserProfileSuccess = ((userprofiledata) => {
    return {
        type: USER_PROFILE_ACTIONS.GET_USER_PROFILE_SUCCESS,
        userprofiledata: userprofiledata
    }
});


