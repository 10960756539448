/**
****************************************************************************************
Project: Readabl
File: actionTypes.js
Description: This file contains the action types.
© Healthcare Triangle, Inc
****************************************************************************************
**/

export const USER_PROFILE_ACTIONS = {
    GET_USER_PROFILE_ERROR: 'GET_USER_PROFILE_ERROR',
    GET_USER_PROFILE_LOADING: 'GET_USER_PROFILE_LOADING',
    GET_USER_PROFILE_SUCCESS: 'GET_USER_PROFILE_SUCCESS'
}