/**
****************************************************************************************
Project: Readabl
File: ReadablLogo.js
Description:
© Healthcare Triangle, Inc
****************************************************************************************
**/

import React from "react";
import rd_logo from "../../utils/images/rd_logo.svg";
import rd_name_nav from "../../utils/images/rd_name_nav.svg";

const ReadablLogo = () => {
    return <div className="logo-name">
        <img alt="logo" src={rd_logo} className="logo-grp1" />
        <div className="doc-route tabs">
            <img className='rd-nav' src={rd_name_nav} alt="rd_name" />
        </div>
    </div>
}

export default ReadablLogo;