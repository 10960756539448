/**
****************************************************************************************
Project: Readabl
File: SubMenus.js
Description: This component renders sub menus options.
© Healthcare Triangle, Inc
****************************************************************************************
**/

import { MenuItem, Paper, Popper } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const SubMenus = (props) => {
    return <>
        {Object.keys(props.subMenus)?.length
            ? (props.subMenus || []).map((subMenu, index) => (
                <>
                    <Popper
                        id={"menu-item-container menu-item-" + subMenu.key}
                        aria-labelledby="demo-positioned-button"
                        anchorEl={subMenu.anchorEl}
                        open={Boolean(subMenu.anchorEl)}
                        onClose={props.handleClose}
                        placement={index == 0 ? "bottom-start" : "right-start"}
                        sx={{
                            zIndex: 99999,
                            "> div": {
                                padding: "10px",
                                marginLeft: index != 0 ? "10px" : "unset"
                            }
                        }}
                    >
                        <Paper elevation={12} onMouseLeave={e => props.subMenus.length - 1 == index && props.handleClose()}>
                            {(subMenu.children || []).map((item, index) => (
                                <NavLink
                                    exact
                                    to={item.children ? {} : item.path}
                                    className="headLinkStyle"
                                    key={index}
                                >
                                    <MenuItem
                                        className={
                                            'navbar-submenu-item ' +
                                            (props.selectedMenu?.split("/").includes(item.value)
                                                ? 'navbar-submenu-item-selected'
                                                : '')
                                        }
                                        key={item.value}
                                        onMouseEnter={(e) => props.handleSubMenuMouseEnter(e, item)}
                                        onClick={(e) => !item.children?.length && props.handleMenuSelect(e, item)}
                                    >
                                        {item.label}
                                        {item?.children ? (
                                            props.subMenus.filter((m) => m.key == item.value).length ? (
                                                <KeyboardArrowLeftIcon />
                                            ) : (
                                                <KeyboardArrowRightIcon />
                                            )
                                        ) : null}
                                    </MenuItem>
                                </NavLink>
                            ))}
                        </Paper>
                    </Popper>
                </>
            ))
            : null
        }
    </>
}

export default SubMenus;