/**
****************************************************************************************
Project: Readabl
File: MainMenu.js
Description: This component renders parent menu in navigation component.
© Healthcare Triangle, Inc
****************************************************************************************
**/

import { MenuItem } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const MainMenu = (props) => {
    return <>
        {(props.options || []).map((menu, i) => {
            return (
                <NavLink
                    exact
                    to={menu.path}
                    className="headLinkStyle"
                >
                    <MenuItem
                        className={
                            'navbar-menu-item ' +
                            (props.selectedMenu?.split("/").includes(menu.value)
                                ? 'navbar-menu-item-selected'
                                : '')
                        }
                        key={menu.value}
                        onMouseEnter={(e) => menu.children?.length && props.handleMouseEnter(e, menu)}
                        onClick={(e) => props.handleMenuSelect(e, menu)}
                    >
                        {menu.label}
                        {menu?.children ? (
                            props.subMenus.filter((m) => m.key == menu.value).length ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )
                        ) : null}
                    </MenuItem>
                </NavLink>
            );
        })}
    </>
}

export default MainMenu;