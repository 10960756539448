/**
****************************************************************************************
Project: Readabl
File: actions.js
Description: This file contains the api call actions.
© Healthcare Triangle, Inc
****************************************************************************************
**/

import client from '../../../utils/api/interceptor';
import { getGnrlSettingsActions } from './actionCreators';
import * as c from '../../../utils/constants/constants';
import {getToken} from "../../../commonFunctions/getToken";

export const getGnrlSettings = ((url) => {
    return async(dispatch) => {
        dispatch(getGnrlSettingsActions.getGnrlSettingsActionsLoading(true));
      
        let token = await getToken();
        let options = {headers: {
            Authorization: "Bearer " + token,
          }};
        client.get(url,options)
            .then((response) => {
                if(response.status !== 200) {
                    throw Error(response.statusText);
                }

                dispatch(getGnrlSettingsActions.getGnrlSettingsActionsLoading(false));
                return response;
            })
            .then((response) => {
                localStorage.setItem(c.SETTINGS,JSON.stringify(response.data.data[0]));
               dispatch(getGnrlSettingsActions.getGnrlSettingsActionsSuccess(response.data.data[0]))})
            .catch(() => dispatch(getGnrlSettingsActions.getGnrlSettingsActionsError(true)));
    }
});


export const passDocId = ((doc_id) => {
    return (dispatch) => {
        dispatch(getGnrlSettingsActions.passDocIDSuccess(doc_id));   
    }
});
