/**
****************************************************************************************
Project: Readabl
File: actionTypes.js
Description: This file contains the action types.
© Healthcare Triangle, Inc
****************************************************************************************
**/

export const SA_ACTIONS = {
    GET_DOC_STUCK_ERROR: 'GET_DOC_STUCK_ERROR',
    GET_DOC_STUCK_LOADING: 'GET_DOC_STUCK_LOADING',
    GET_DOC_STUCK_SUCCESS: 'GET_DOC_STUCK_SUCCESS'
}