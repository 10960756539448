/**
****************************************************************************************
Project: Readabl
File: actionTypes.js
Description: This file contains the action types.
© Healthcare Triangle, Inc
****************************************************************************************
**/

export const AUDIT_ACTIONS = {
    GET_AUDIT_ERROR: 'GET_AUDIT_ERROR',
    GET_AUDIT_LOADING: 'GET_AUDIT_LOADING',
    GET_AUDIT_SUCCESS: 'GET_AUDIT_SUCCESS',

    GET_ALL_AUDIT_ERROR: 'GET_ALL_AUDIT_ERROR',
    GET_ALL_AUDIT_LOADING: 'GET_ALL_AUDIT_LOADING',
    GET_ALL_AUDIT_SUCCESS: 'GET_ALL_AUDIT_SUCCESS',

    AUDIT_RESULT_ERROR:'AUDIT_RESULT_ERROR',
    AUDIT_RESULT_SUCCESS:'AUDIT_RESULT_SUCCESS',
    AUDIT_RESULT_LOADING: 'AUDIT_RESULT_LOADING'
}