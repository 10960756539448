/**
****************************************************************************************
Project: Readabl
File: reducer.js
Description: This file contains the redux store update based on action types.
© Healthcare Triangle, Inc
****************************************************************************************
**/

import {PATIENT_CREATE_ACTIONS_CONSTANTS } from "../actions/actionTypes";

const defaultState = {patient_create_modal: {open: false, message: ""}, maximise: true, patient_info: {}, doc_info: {} };
export function patient_create(state = {...defaultState}, action) {
    switch(action.type) {
        case PATIENT_CREATE_ACTIONS_CONSTANTS.TOGGLE_PATIENT_CREATE_POPUP:
            return  { ...state, patient_create_modal: action.payload} 
        case PATIENT_CREATE_ACTIONS_CONSTANTS.MAXIMISE_PATIENT_CREATE_POPUP:
            return { ...state, maximise: action.payload} 
        case PATIENT_CREATE_ACTIONS_CONSTANTS.UPDATE_PATIENT_CREATE_DETAILS:
            return { ...state, patient_info: action.payload} 
        case PATIENT_CREATE_ACTIONS_CONSTANTS.UPDATE_DOC_DETAILS:
            return { ...state, doc_info: action.payload} 
        default:
            return state;
    }
}