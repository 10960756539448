/**
****************************************************************************************
Project: Readabl
File: actionTypes.js
Description: This file contains the action types.
© Healthcare Triangle, Inc
****************************************************************************************
**/

export const GNRL_SETTINGS_ACTION = {
    GNRL_SETTINGS_LOADING: 'GNRL_SETTINGS_LOADING',
    GNRL_SETTINGS_ERROR: 'GNRL_SETTINGS_ERROR',
    GNRL_SETTINGS_SUCCESS: 'GNRL_SETTINGS_SUCCESS',
    UPDATE_SELECTED_MENU: "UPDATE_SELECTED_MENU",
    PASS_DOC_ID :'PASS_DOC_ID',

}