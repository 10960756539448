/**
****************************************************************************************
Project: Readabl
File: store.js
Description: This file contains intializing store.
© Healthcare Triangle, Inc
****************************************************************************************
**/

import { createStore, applyMiddleware} from 'redux';
import { thunk } from 'redux-thunk';
import rootReducer from '../rootReducer';
import { composeWithDevTools } from "@redux-devtools/extension";

const configureStore = ((initialState) => {
    const middleware = applyMiddleware(thunk);
    return createStore(
    rootReducer,
    undefined,
    composeWithDevTools(middleware)
    );
});

export default configureStore;
